import { lazy } from "react";

const PagesRoutes = [
  // Authentication
  {
    path: "/login",
    component: lazy(() => import("../../view/pages/auth/login")),
    layout: "FullLayout",
    auth: false,
  },
  {
    path: "/register",
    component: lazy(() => import("../../view/pages/auth/register")),
    layout: "FullLayout",
    auth: false,
  },
  {
    path: "/forgot-password",
    component: lazy(() => import("../../view/pages/auth/forgot-password")),
    layout: "FullLayout",
    auth: false,
  },
  {
    path: "/reset-password",
    component: lazy(() => import("../../view/pages/auth/reset-password")),
    layout: "FullLayout",
    auth: false,
  },
  {
    path: "/auth/verification",
    component: lazy(() =>
      import("../../view/pages/auth/verification/verified")
    ),
    layout: "FullLayout",
    auth: false,
  },
  {
    path: "/account-status/verification",
    component: lazy(() => import("../../view/pages/auth/verification/verify")),
    layout: "FullLayout",
    auth: false,
  },

  // Package Status
  {
    path: "/account-status/pending",
    component: lazy(() =>
      import("../../view/pages/auth/account-status/pending")
    ),
    layout: "FullLayout",
    auth: false,
  },
  {
    path: "/account-status/expired",
    component: lazy(() =>
      import("../../view/pages/auth/account-status/expired")
    ),
    layout: "FullLayout",
    auth: false,
  },
  {
    path: "/account-status/rejected",
    component: lazy(() =>
      import("../../view/pages/auth/account-status/rejected")
    ),
    layout: "FullLayout",
    auth: false,
  },

  // Dashboard
  {
    expact: true,
    path: "/dashboard",
    component: lazy(() => import("../../view/pages/dashboard")),
    layout: "VerticalLayout",
    auth: true,
  },

  // Bill
  {
    path: "/bill/data",
    component: lazy(() => import("../../view/pages/bill/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/cashier/invoices",
    component: lazy(() => import("../../view/pages/bill/paid")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/bill/detail",
    component: lazy(() => import("../../view/pages/bill/detail")),
    layout: "VerticalLayout",
    auth: true,
  },
  // Statistic
  {
    path: "/statistic",
    component: lazy(() => import("../../view/pages/statistic")),
    layout: "VerticalLayout",
    auth: true,
  },
  // Pengeluaran
  {
    path: "/spending/data",
    component: lazy(() => import("../../view/pages/report/spending/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/spending/form",
    component: lazy(() => import("../../view/pages/report/spending/form")),
    layout: "VerticalLayout",
    auth: true,
  },

  // Booking
  {
    path: "/booking/data",
    component: lazy(() => import("../../view/pages/booking/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/booking/form",
    component: lazy(() => import("../../view/pages/booking/form")),
    layout: "VerticalLayout",
    auth: true,
  },
  // Patient
  {
    path: "/patient/data",
    component: lazy(() => import("../../view/pages/patient/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/patient/detail",
    component: lazy(() => import("../../view/pages/patient/detail")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/patient/print/acceptance-form",
    component: lazy(() =>
      import("../../view/pages/patient/print/acceptance-form")
    ),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/patient/form",
    component: lazy(() => import("../../view/pages/patient/form")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/patient/import",
    component: lazy(() => import("../../view/pages/patient/import")),
    layout: "VerticalLayout",
    auth: true,
  },
  // Assesment
  {
    path: "/assesment/data",
    component: lazy(() => import("../../view/pages/assesment")),
    layout: "VerticalLayout",
    auth: true,
  },

  // Report
  {
    path: "/report/data",
    component: lazy(() => import("../../view/pages/report/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/report/daily",
    component: lazy(() => import("../../view/pages/report/daily")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/report/finance",
    component: lazy(() => import("../../view/pages/report/finance")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/report/clinic",
    component: lazy(() => import("../../view/pages/report/clinic")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/report/piutang",
    component: lazy(() => import("../../view/pages/report/piutang")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/report/dinkes",
    component: lazy(() => import("../../view/pages/report/dinkes")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/report/tax",
    component: lazy(() => import("../../view/pages/report/tax")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/report/medical-service",
    component: lazy(() => import("../../view/pages/report/medical-service")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/medical-service/detail",
    component: lazy(() =>
      import("../../view/pages/report/detail/medical-service")
    ),
    layout: "VerticalLayout",
    auth: true,
  },

  // Package
  {
    path: "/package/history",
    component: lazy(() => import("../../view/pages/package/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/package/invoice",
    component: lazy(() => import("../../view/pages/package/detail")),
    layout: "VerticalLayout",
    auth: true,
  },

  // User
  {
    path: "/user/admin-clinic/data",
    component: lazy(() => import("../../view/pages/user/admin/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/user/admin-clinic/form",
    component: lazy(() => import("../../view/pages/user/admin/form")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/user/doctor/data",
    component: lazy(() => import("../../view/pages/user/doctor/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/user/doctor/detail",
    component: lazy(() => import("../../view/pages/user/doctor/detail")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/user/doctor/form",
    component: lazy(() => import("../../view/pages/user/doctor/form")),
    layout: "VerticalLayout",
    auth: true,
  },

  // Profile
  {
    path: "/profile",
    component: lazy(() => import("../../view/pages/profile")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/change-password",
    component: lazy(() => import("../../view/pages/setting/password")),
    layout: "VerticalLayout",
    auth: true,
  },
  // Setting
  {
    path: "/setting/clinic",
    component: lazy(() => import("../../view/pages/setting/clinic/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/setting/printer",
    component: lazy(() => import("../../view/pages/setting/printer/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/setting/operational-hours",
    component: lazy(() =>
      import("../../view/pages/setting/operational-hours/data")
    ),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/setting/price/data",
    component: lazy(() => import("../../view/pages/setting/price/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/setting/price/form",
    component: lazy(() => import("../../view/pages/setting/price/form")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/setting/payment-method/data",
    component: lazy(() =>
      import("../../view/pages/setting/payment-method/data")
    ),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/setting/payment-method/form",
    component: lazy(() =>
      import("../../view/pages/setting/payment-method/form")
    ),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/setting/stock-opname/data",
    component: lazy(() => import("../../view/pages/setting/stock-opname/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/setting/stock-opname/form",
    component: lazy(() => import("../../view/pages/setting/stock-opname/form")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/setting/diagnose/data",
    component: lazy(() => import("../../view/pages/setting/diagnose/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/integration/satusehat",
    component: lazy(() => import("../../view/pages/setting/satusehat")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/integration/satusehat-logs",
    component: lazy(() =>
      import("../../view/pages/setting/satusehat/detail-log")
    ),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/event/data",
    component: lazy(() => import("../../view/pages/event/data")),
    layout: "VerticalLayout",
    auth: true,
  },
];

export default PagesRoutes;
